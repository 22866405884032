import React from 'react'

const Footer = () => {
    return (
        <footer className="text-center text-white bg-yellow-500">
            <p>All Rights Reserved</p>
        </footer>
    )
}

export default Footer
