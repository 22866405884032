import React from 'react'
import Footer from './footer'
import Hearder from './hearder'

const Layout = ({children}) => {
    return (
        <div className="font-sans">
            <Hearder />
            <div className="flex justify-center">
                <div className="max-w-4xl flex flex-col items-center m-8">{children}</div>
            </div>
            <Footer />
        </div>
    )
}

export default Layout
