import React from 'react'
import { Link } from 'gatsby'
const Hearder = () => {
    return (
        <div className="text-center text-white text-5xl bg-yellow-500 h-24">
            <div className="p-6"><Link className="text-white no-underline" to='/'>Ekinu</Link></div>
        </div>
    )
}

export default Hearder
